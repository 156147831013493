.mdi-navigation-menu {
  padding-left: 0.5rem;
}
#slide-out .card {
  margin: 0;
}
#slide-out .card img {
  height: 140px;
}
#slide-out .card-title {
  left: 0;
  top: -25px;
  width: 100%;
  height: 100%;
}
#slide-out .card-title span {
  display: block;
  line-height: 30px;
}
#slide-out .card-title .menu-title {
  font-weight: bold;
}
#slide-out .card-title .menu-speach {
  font-size: 1rem;
  line-height: 20px;
}
#slide-out .card-title .menu-footer {
  font-size: 1rem;
  line-height: 20px;
  position: relative;
  bottom: -45px;
  left: 145px;
}
#slide-out i {
  color: #1a687f;
  width: 5rem;
  float: left;
}
#slide-out a {
  padding-left: 7px;
  font-size: 1.5rem;
}
.side-nav.side-nav-icons {
  width: 5rem;
  z-index: 1000;
  box-shadow: none;
}
#slide-out li {
  padding: 0;
}
ul#slide-out li:hover {
  background-color: #f0f0f0;
}
@media screen and (max-width: 400px) {
  #slide-out {
    width: 20rem;
    background-color: #fff;
    max-width: 95%;
    -webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media screen and (min-width: 400px) {
  #slide-out {
    left: 0;
    width: 0;
    background-color: #fff;
    max-width: 95%;
    -webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  #slide-out:before {
/*background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent)*/
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 100%;
  }
  #slide-out li {
    width: 20rem;
    padding: 0;
  }
}
.nav-wrapper a {
  padding-left: 10px;
}
html {
  background-color: #fff;
}
body {
  overflow: auto;
}
body,
main {
  height: 100%;
  width: 100%;
  position: absolute;
}
head,
main {
  background-color: #fff !important;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  transform: translateZ(0);
}
main {
  overflow: hidden;
}
.home-main {
  padding-top: 80px;
  padding-bottom: 64px;
}
nav {
  background-color: #1a687f;
  position: fixed;
  top: 0;
  z-index: 10;
}
.block-title {
  font-family: monospace;
}
.issuer-title {
  color: #1a687f;
}
.issuer-date {
  font-family: monospace;
  padding: 3px 6px 3px 6px;
}
.issuer {
  font-family: monospace;
  background-color: #eee;
  padding: 3px 6px 3px 6px;
}
.paid {
  margin-top: -22px;
  margin-right: -10px;
}
.paid i {
  margin-top: 12px;
  font-size: 30px;
}
.paid .amount {
  margin-top: 12px;
  font-size: 13px;
}
.paid.chip {
  line-height: 24px;
  height: 24px;
  background-color: #c9ffd0;
}
.collection .fa {
  position: absolute;
  width: 42px;
  height: 42px;
  overflow: hidden;
  left: 15px;
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 42px;
  color: #fff;
  background-color: #1a687f;
  text-align: center;
  border-radius: 50%;
}
.card-content.head {
  font-size: 24px;
  text-align: center;
}
.card-action.head {
  font-size: 24px;
  text-align: center;
}
.collection .collection-item.avatar {
  min-height: 0 !important;
}
.topx i {
  font-size: 12px !important;
  line-height: 24px !important;
  height: 24px !important;
  width: 24px !important;
}
.topx.collection.with-header .collection-item.avatar {
  padding-left: 50px !important;
}
.card-action.donate {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.8em;
}
