.mdi-navigation-menu {
  padding-left: 0.5rem;
}
#slide-out .card {
  margin: 0;
}
#slide-out .card img {
  height: 140px;
}
#slide-out .card-title {
  left: 0;
  top: -25px;
  width: 100%;
  height: 100%;
}
#slide-out .card-title span {
  display: block;
  line-height: 30px;
}
#slide-out .card-title .menu-title {
  font-weight: bold;
}
#slide-out .card-title .menu-speach {
  font-size: 1rem;
  line-height: 20px;
}
#slide-out .card-title .menu-footer {
  font-size: 1rem;
  line-height: 20px;
  position: relative;
  bottom: -45px;
  left: 145px;
}
#slide-out i {
  color: #1a687f;
  width: 5rem;
  float: left;
}
#slide-out a {
  padding-left: 7px;
  font-size: 1.5rem;
}
.side-nav.side-nav-icons {
  width: 5rem;
  z-index: 1000;
  box-shadow: none;
}
#slide-out li {
  padding: 0;
}
ul#slide-out li:hover {
  background-color: #f0f0f0;
}
@media screen and (max-width: 400px) {
  #slide-out {
    width: 20rem;
    background-color: #fff;
    max-width: 95%;
    -webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media screen and (min-width: 400px) {
  #slide-out {
    left: 0;
    width: 0;
    background-color: #fff;
    max-width: 95%;
    -webkit-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  #slide-out:before {
/*background-image: linear-gradient(to left, rgba(0, 0, 0, 0.3), transparent)*/
    pointer-events: none;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 100%;
  }
  #slide-out li {
    width: 20rem;
    padding: 0;
  }
}
.nav-wrapper a {
  padding-left: 10px;
}
