nav li .fa {
  display: block;
}
#slide-out .fa {
  padding-left: 0.5rem;
  line-height: 64px;
}
@media screen and (max-width: 400px) {
  nav li .fa {
    line-height: 56px;
  }
}
